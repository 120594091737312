export const mapLocations = (t) => [
  {
    title: t("locations.no-1.title"),
    address: t("locations.no-1.address"),
    city: t("locations.no-1.city"),
    phone: t("locations.no-1.phone"),
    url: t("locations.no-1.url"),
    tag: t("locations.pharmacy24"),
  },
  {
    title: t("locations.no-2a.title"),
    address: t("locations.no-2a.address"),
    city: t("locations.no-2a.city"),
    phone: t("locations.no-2a.phone"),
    url: t("locations.no-2a.url"),
  },
  {
    title: t("locations.no-4.title"),
    address: t("locations.no-4.address"),
    city: t("locations.no-4.city"),
    phone: t("locations.no-4.phone"),
    url: t("locations.no-4.url"),
  },
  {
    title: t("locations.no-5a.title"),
    address: t("locations.no-5a.address"),
    city: t("locations.no-5a.city"),
    phone: t("locations.no-5a.phone"),
    url: t("locations.no-5a.url"),
  },
  {
    title: t("locations.no-6.title"),
    address: t("locations.no-6.address"),
    city: t("locations.no-6.city"),
    phone: t("locations.no-6.phone"),
    url: t("locations.no-6.url"),
  },
  {
    title: t("locations.no-7.title"),
    address: t("locations.no-7.address"),
    city: t("locations.no-7.city"),
    phone: t("locations.no-7.phone"),
    url: t("locations.no-7.url"),
  },
  {
    title: t("locations.no-8.title"),
    address: t("locations.no-8.address"),
    city: t("locations.no-8.city"),
    phone: t("locations.no-8.phone"),
    url: t("locations.no-8.url"),
  },
  {
    title: t("locations.no-9a.title"),
    address: t("locations.no-9a.address"),
    city: t("locations.no-9a.city"),
    phone: t("locations.no-9a.phone"),
    url: t("locations.no-9a.url"),
  },
  {
    title: t("locations.no-10a.title"),
    address: t("locations.no-10a.address"),
    city: t("locations.no-10a.city"),
    phone: t("locations.no-10a.phone"),
    url: t("locations.no-10a.url"),
  },
  {
    title: t("locations.no-11a.title"),
    address: t("locations.no-11a.address"),
    city: t("locations.no-11a.city"),
    phone: t("locations.no-11a.phone"),
    url: t("locations.no-11a.url"),
  },
  {
    title: t("locations.no-12.title"),
    address: t("locations.no-12.address"),
    city: t("locations.no-12.city"),
    phone: t("locations.no-12.phone"),
    url: t("locations.no-12.url"),
  },
  {
    title: t("locations.no-13.title"),
    address: t("locations.no-13.address"),
    city: t("locations.no-13.city"),
    phone: t("locations.no-13.phone"),
    url: t("locations.no-13.url"),
  },
  {
    title: t("locations.no-15.title"),
    address: t("locations.no-15.address"),
    city: t("locations.no-15.city"),
    phone: t("locations.no-15.phone"),
    url: t("locations.no-15.url"),
  },
  {
    title: t("locations.no-16.title"),
    address: t("locations.no-16.address"),
    city: t("locations.no-16.city"),
    phone: t("locations.no-16.phone"),
    url: t("locations.no-16.url"),
  },
  {
    title: t("locations.no-18.title"),
    address: t("locations.no-18.address"),
    city: t("locations.no-18.city"),
    phone: t("locations.no-18.phone"),
    url: t("locations.no-18.url"),
  },
  {
    title: t("locations.no-19.title"),
    address: t("locations.no-19.address"),
    city: t("locations.no-19.city"),
    phone: t("locations.no-19.phone"),
    url: t("locations.no-19.url"),
  },
  {
    title: t("locations.no-20a.title"),
    address: t("locations.no-20a.address"),
    city: t("locations.no-20a.city"),
    phone: t("locations.no-20a.phone"),
    url: t("locations.no-20a.url"),
  },
  {
    title: t("locations.no-21.title"),
    address: t("locations.no-21.address"),
    city: t("locations.no-21.city"),
    phone: t("locations.no-21.phone"),
    url: t("locations.no-21.url"),
  },
  {
    title: t("locations.no-22.title"),
    address: t("locations.no-22.address"),
    city: t("locations.no-22.city"),
    phone: t("locations.no-22.phone"),
    url: t("locations.no-22.url"),
  },
  {
    title: t("locations.no-23.title"),
    address: t("locations.no-23.address"),
    city: t("locations.no-23.city"),
    phone: t("locations.no-23.phone"),
    url: t("locations.no-23.url"),
    tag: t("locations.pharmacy24"),
  },
  {
    title: t("locations.no-24.title"),
    address: t("locations.no-24.address"),
    city: t("locations.no-24.city"),
    phone: t("locations.no-24.phone"),
    url: t("locations.no-24.url"),
  },
  {
    title: t("locations.no-25.title"),
    address: t("locations.no-25.address"),
    city: t("locations.no-25.city"),
    phone: t("locations.no-25.phone"),
    url: t("locations.no-25.url"),
  },
];
