import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  StyledContainer,
  StyledParagraph,
  StyledContactContainerImg,
  StyledPhoneContainer,
} from "./Contact.styled";
import contactImg from "../../assets/contact/contact.webp";
import LinkLabel from "../LinkLabel/LinkLabel";

const Contact = ({ $variant }) => {
  const { t } = useTranslation();

  const formatPhoneForHref = (phone) => phone.replace(/\s+/g, "");

  return (
    <StyledContainer $variant={$variant}>
      <Row>
        <Col xs={12} lg={6} className="mb-lg-0 mb-5">
          <h2>{t("contact.title")}</h2>
          <StyledParagraph>{t("contact.desc.address")}</StyledParagraph>
          <LinkLabel
            href={t("contact.address.url")}
            target="_blank"
            rel="noreferrer"
            label={t("contact.address")}
          />
          <StyledParagraph className="mb-5">
            {t("contact.workingHours.location")}
          </StyledParagraph>
          <StyledParagraph>{t("contact.desc.callUs")}</StyledParagraph>
          <StyledPhoneContainer>
            <LinkLabel
              href={`tel:${formatPhoneForHref(t("contact.phone"))}`}
              label={t("contact.phone")}
            />
            <LinkLabel
              href={`tel:${formatPhoneForHref(t("contact.phoneTwo"))}`}
              label={t("contact.phoneTwo")}
            />
          </StyledPhoneContainer>
          <StyledParagraph className="mb-5">
            {t("contact.workingHours.phoneService")}
          </StyledParagraph>
          <StyledParagraph>{t("contact.desc.writeUs")}</StyledParagraph>
          <LinkLabel
            href={`mailto:${t("contact.mail")}`}
            label={t("contact.mail")}
          />
          <StyledParagraph className="mt-5">
            {t("contact.desc.general")}
          </StyledParagraph>
        </Col>
        <Col xs={12} lg={6}>
          <StyledContactContainerImg>
            <img src={contactImg} alt="contact" />
          </StyledContactContainerImg>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Contact;
