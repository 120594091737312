import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GoLocation, GoXCircle } from "react-icons/go";
import { RiErrorWarningFill } from "react-icons/ri";

import {
  StyledCardWrapper,
  StyledCol,
  StyledContainer,
  StyledContentContainer,
  StyledIconContainer,
  StyledParagraph,
  StyledInput,
  StyledIconLink,
  StyledImgContainer,
  StyledButtonSearch,
  StyledButtonReset,
  StyledInputContainer,
  StyledRow,
  StyledInfoTextContainer,
  StyledButtonContainer,
  StyledInfoIconContainer,
  StyledInfoText,
  StyledHighlitedText,
  StyledTag24,
} from "./LocationPharmacy.styled";
import CustomBtn from "../CustomBtn/CustomBtn";

import location from "../../assets/location/location.webp";
import LinkLabel from "../LinkLabel/LinkLabel";

import { mapLocations as getMapLocations } from "./mapLocations";

const LocationPharmacy = ({
  limit,
  locationButton,
  $variant,
  enableSearch,
  homepageLocationShortDesc,
  locationPageDesc,
  locationPageImage,
  md,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mapLocations = getMapLocations(t);

  const [searchTerm, setSearchTerm] = useState("");
  const [finalSearchTerm, setFinalSearchTerm] = useState("");
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;

    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]+/;

    if (!specialCharRegex.test(inputValue)) {
      setSearchTerm(inputValue);

      // Provera da li je polje prazno
      if (inputValue.trim() === "") {
        handleResetClick(); // Resetuj filter
      } else if (inputValue.length < 3) {
        setIsSearchClicked(false);
      }
    }
  };

  const handleSearchClick = () => {
    setFinalSearchTerm(searchTerm);
    setIsSearchClicked(true);
  };

  const handleResetClick = () => {
    setSearchTerm("");
    setFinalSearchTerm("");
    setIsSearchClicked(false);
  };

  let filteredLocations = mapLocations;

  const convertToLatin = (str) => {
    const replaceMap = {
      š: "s",
      ć: "c",
      č: "c",
      đ: "dj",
      ž: "z",
      Š: "S",
      Ć: "C",
      Č: "C",
      Đ: "Dj",
      Ž: "Z",
    };

    return str.replace(/[šćčđžŠĆČĐŽ]/g, (char) => replaceMap[char] || char);
  };

  if (enableSearch) {
    filteredLocations = mapLocations
      .filter((location) => {
        const searchTermLower = convertToLatin(finalSearchTerm.toLowerCase());

        // Proveri da li je vrednost prisutna pre nego što pozoveš toLowerCase
        return (
          (location.title &&
            convertToLatin(location.title.toLowerCase()).includes(
              searchTermLower
            )) ||
          (location.city &&
            convertToLatin(location.city.toLowerCase()).includes(
              searchTermLower
            )) ||
          (location.address &&
            convertToLatin(location.address.toLowerCase()).includes(
              searchTermLower
            )) ||
          (location.tag &&
            convertToLatin(location.tag.toLowerCase()).includes(
              searchTermLower
            ))
        );
      })
      .sort((a, b) => {
        if (a.tag && !b.tag) return -1;
        if (!a.tag && b.tag) return 1;
        return 0;
      });
  } else {
    filteredLocations = mapLocations.sort((a, b) => {
      if (a.tag && !b.tag) return -1;
      if (!a.tag && b.tag) return 1;
      return 0;
    });
  }

  const locationsToShow = limit
    ? filteredLocations.slice(0, limit)
    : filteredLocations;

  const handleClick = () => {
    navigate(t("nav.location.url"));
  };

  const formatPhoneForHref = (phone) => phone.replace(/\s+/g, "");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.length >= 3) {
      handleSearchClick();
    }
  };

  const noResultsText = t("locationPharmacy.noResults", {
    searchTerm: `**${finalSearchTerm}**`,
  });

  return (
    <StyledContainer $variant={$variant}>
      <StyledRow>
        <Col md={md} className="pe-3 pe-md-5">
          <h2>{t("locationPharmacy.title")}</h2>
          {homepageLocationShortDesc && (
            <StyledParagraph>{t("locationPharmacy.desc")}</StyledParagraph>
          )}
          {locationPageDesc && (
            <>
              <StyledParagraph>
                {t("locationPharmacy.page.desc")}
              </StyledParagraph>
              <StyledParagraph>
                {t("locationPharmacy.page.desc2")}
              </StyledParagraph>
            </>
          )}
        </Col>

        {locationPageImage && (
          <Col md={5}>
            <StyledImgContainer>
              <img src={location} alt={t("locationPharmacy.title")} />
            </StyledImgContainer>
          </Col>
        )}
      </StyledRow>

      {enableSearch && (
        <Row className={isSearchClicked ? "mb-3" : "mb-5"}>
          <Col>
            <form id="search-form" onSubmit={handleFormSubmit}>
              <StyledInputContainer>
                <StyledInput
                  id="search-input"
                  type="text"
                  placeholder={t("locationPharmacy.page.searchPlaceholder")}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <StyledButtonContainer>
                  <StyledButtonSearch
                    type="submit"
                    disabled={searchTerm.length < 3}
                  >
                    {t("locationPharmacy.search")}
                  </StyledButtonSearch>
                  {isSearchClicked && (
                    <StyledButtonReset onClick={handleResetClick}>
                      <GoXCircle />
                    </StyledButtonReset>
                  )}
                </StyledButtonContainer>
              </StyledInputContainer>
            </form>
          </Col>
        </Row>
      )}

      {enableSearch && isSearchClicked && (
        <Row className="mb-3">
          <Col>
            {filteredLocations.length > 0 ? (
              <StyledInfoText>
                {t("locationPharmacy.resultsFound", {
                  count: filteredLocations.length,
                })}
              </StyledInfoText>
            ) : (
              <StyledInfoTextContainer className="mt-3">
                <StyledInfoIconContainer>
                  <RiErrorWarningFill size={"2em"} />
                </StyledInfoIconContainer>
                <StyledInfoText>
                  {noResultsText
                    .split("**")
                    .map((part, index) =>
                      index % 2 === 1 ? (
                        <StyledHighlitedText key={index}>
                          {part}
                        </StyledHighlitedText>
                      ) : (
                        part
                      )
                    )}
                </StyledInfoText>
              </StyledInfoTextContainer>
            )}
          </Col>
        </Row>
      )}

      {(filteredLocations.length !== 0 || finalSearchTerm === "") && (
        <Row className="locations">
          {locationsToShow.length &&
            locationsToShow.map(({ title, address, city, phone, url, tag }) => (
              <Col md={6} xl={4} xxl={3} key={title} className="mb-4">
                <StyledCardWrapper $index={`${title}`}>
                  <StyledIconContainer>
                    <StyledIconLink href={url} target="_blank" rel="noreferrer">
                      <GoLocation />
                    </StyledIconLink>
                  </StyledIconContainer>

                  <StyledContentContainer>
                    {tag && <StyledTag24>{tag}</StyledTag24>}
                    <h3>{title}</h3>
                    <p>{address}</p>
                    <p className="mb-3">{city}</p>
                    {!tag && <p>{t("locations.pharmacy")}</p>}
                    <LinkLabel
                      href={`tel:${formatPhoneForHref(phone)}`}
                      label={phone}
                    />
                  </StyledContentContainer>
                </StyledCardWrapper>
              </Col>
            ))}
        </Row>
      )}

      {locationButton && (
        <Row>
          <StyledCol xs={12}>
            <CustomBtn
              label={t("locationPharmacy.btnLabel")}
              onClick={handleClick}
            />
          </StyledCol>
        </Row>
      )}
    </StyledContainer>
  );
};

export default LocationPharmacy;
