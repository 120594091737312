import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledLogo = styled.img`
  width: 400px;
  height: 100px;

  ${breakpoints.down("xl")} {
    width: 340px;
    height: 100px;
  }

  ${breakpoints.down("md")} {
    width: 200px;
    height: 50px;
  }
`;

export const StyledLi = styled.li`
  text-transform: uppercase;
`;

export const StyledLanguageSelectorContainer = styled.div`
  position: relative;
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;

  ${breakpoints.down("lg")} {
    margin: 15px 0;
    justify-content: start;
  }

  ul {
    position: absolute;
    padding-left: 0;
  }
`;
