import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo/logo.svg";
import { StyledLi, StyledLogo } from "./Nav.styled";
import { useTranslation } from "react-i18next";
import { navigation as getNavLinks } from "./NavLinks";
import { IoCloseSharp } from "react-icons/io5";

const Nav = () => {
  const { t } = useTranslation();
  const navigation = getNavLinks(t);
  // const [isOpen, setIsOpen] = useState(false);
  // const openNav = () => setIsOpen(true);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand me-0" href={t("nav.home.url")}>
          <StyledLogo src={logo} alt="family pharm logo" />
        </a>
        <button
          className="navbar-toggler navbar-toggler__custom"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavOpen}
          aria-label="Toggle navigation"
          onClick={toggleNav}
        >
          {!isNavOpen ? (
            <span className="navbar-toggler-icon"></span>
          ) : (
            <span className="custom-close-icon">
              <IoCloseSharp />
            </span>
          )}
        </button>
        <div
          className={`navbar-collapse justify-content-end ${
            isNavOpen ? "slide-down" : "slide-up"
          }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 navbar-nav--border-bottom align-items-start align-items-lg-center">
            {navigation.length &&
              navigation.map(({ label, url }, index) => (
                <StyledLi key={index}>
                  <NavLink
                    index={`${index} - ${label}`}
                    className="nav-link"
                    to={url}
                    activeclassname="active"
                    onClick={() => {
                      closeNav();
                      document.dispatchEvent(
                        new Event("closeLanguageSelector")
                      );
                    }}
                  >
                    {label}
                  </NavLink>
                </StyledLi>
              ))}
            {/* In need of language selector */}
            {/* <StyledLanguageSelectorContainer>
              <LanguageSelector closeNav={closeNav} />
            </StyledLanguageSelectorContainer> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
