import styled, { css } from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledContainer = styled.div`
  padding: 150px 20px;

  ${(props) =>
    props.$variant === "smallPadding" &&
    css`
      padding: 60px 20px 150px;

      ${breakpoints.down("md")} {
        padding: 30px 20px;
      }
    `}

  ${breakpoints.down("md")} {
    padding: 30px 20px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 18px;
  margin: 0;
`;

export const StyledContactContainerImg = styled.div`
  overflow: hidden;
  border-radius: 40px;

  img {
    vertical-align: unset;
    float: inline-end;
    transform: translateX(180px);

    ${breakpoints.down("md")} {
      height: 400px;
      transform: translateX(66px);
    }
  }
`;

export const StyledPhoneContainer = styled.div`
  display: flex;
  gap: 30px;

  ${breakpoints.down("sm")} {
    gap: unset;
    justify-content: space-between;
  }
`;
